import React from 'react';
import './Imprint.css'

interface ImprintProps {
    isOpen: boolean;
    onClose: () => void;
}


function ImprintComponent(props: ImprintProps) {

    const handleOverlayClick = (e: any) => {
        if (e.target.id === 'overlay') {
            props.onClose();
        }
    };

    return (
        <div>
            {props.isOpen && (
                <div id='overlay' className={'overlay'} onClick={handleOverlayClick}>
                    <div className={'content'}>
                        <h3>Impressum</h3>
                        <p>Sven Mitschke<br/>
                            Alte Salzdahlumer Str. 204b<br/>
                            38124 Braunschweig<br/><br/>
                            mitschke.sven@gmail.com
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ImprintComponent;
