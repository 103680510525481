import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Bounds, Location, OSMElement} from "./types";
import './App.css'
import Imprint from "./imprint/Imprint";
import EmojiSpinner from "./spinner/Spinner";


const overpassUrl = `https://overpass-api.de/api/interpreter`;


function App() {

    const [initialSearchSuccessful, setInitialSearchSuccessful] = useState<boolean>(false);

    const [location, setLocation] = useState<Location | null>(null);
    const [radius, setRadius] = useState<number>(100);

    const [schools, setSchools] = useState<OSMElement[]>([]);
    const [kindergarten, setKindergarten] = useState<OSMElement[]>([]);
    const [youthCentre, setYouthCentre] = useState<OSMElement[]>([]);
    const [childcare, setChildcare] = useState<OSMElement[]>([]);
    const [playground, setPlayground] = useState<OSMElement[]>([]);
    const [sportsCentre, setSportsCentre] = useState<OSMElement[]>([]);
    const [pedestrianZone, setPedestrianZone] = useState<OSMElement[]>([]);

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    const [isImprintOpen, setImprintOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (initialSearchSuccessful) {
            getLocation();
        }

    }, [radius]);


    useEffect(() => {
        if (location) {
            Promise.all([
                axios.post(overpassUrl, getQuery(location.lat, location.lon, 'school'), {headers: {'Content-Type': 'text/plain'}}),
                axios.post(overpassUrl, getQuery(location.lat, location.lon, 'kindergarten'), {headers: {'Content-Type': 'text/plain'}}),
                axios.post(overpassUrl, getQuery(location.lat, location.lon, 'community_centre'), {headers: {'Content-Type': 'text/plain'}}),
                axios.post(overpassUrl, getQuery(location.lat, location.lon, 'childcare'), {headers: {'Content-Type': 'text/plain'}}),
                axios.post(overpassUrl, getQuery(location.lat, location.lon, 'sports_centre'), {headers: {'Content-Type': 'text/plain'}}),
                ...(isBetween7and20() ? [axios.post(overpassUrl, getQuery(location.lat, location.lon, 'pedestrian'), {headers: {'Content-Type': 'text/plain'}})] : []),
                axios.post(overpassUrl, getQuery(location.lat, location.lon, 'playground'), {headers: {'Content-Type': 'text/plain'}})
            ]).then((results) => {
                setSchools(results[0].data.elements);
                setKindergarten(results[1].data.elements);
                setYouthCentre(results[2].data.elements.filter(filterYouthCentres));
                setChildcare(results[3].data.elements);
                setSportsCentre(results[4].data.elements);
                if (isBetween7and20()) {
                    setPedestrianZone(results[5].data.elements)
                }
                setPlayground(results[results.length - 1].data.elements);
            }).finally(() => {
                setLoading(false); // Stop loading
            });
        }
    }, [location]);

    const filterYouthCentres = (yc: OSMElement) => {
        return (yc.tags['community_centre:for']?.includes('juvenile') ||
            yc.tags['community_centre:for']?.includes('child') ||
            yc.tags['community_centre:for']?.includes('youth') ||
            yc.tags['community_centre']?.includes('youth_centre') ||
            yc.tags.name?.toLowerCase().includes('kind') ||
            yc.tags.name?.toLowerCase().includes('jugend'))
    }

    useEffect(() => {
        window.addEventListener('resize', adjustHeight);
        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, []);

    const adjustHeight = () => {
        setViewportHeight(window.innerHeight);
    };

    const getLocation = () => {
        setLoading(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(p => {
                setLocation({lat: p.coords.latitude, lon: p.coords.longitude});
                setInitialSearchSuccessful(true)
            }, e => {
                alert("Dein Standort konnte nicht ermittelt werden. Bitte überprüfe deine Browsereinstellungen (error message: " + e.message + ")")
                console.error(e.message)
            });
        }
    }

    const isAllowed = (): boolean => {
        return schools.length === 0 &&
            kindergarten.length === 0 &&
            childcare.length === 0 &&
            youthCentre.length === 0 &&
            playground.length === 0 &&
            sportsCentre.length === 0 &&
            (pedestrianZone.filter(pz => isWithinBounds(location!, pz.bounds)).length === 0 || !isBetween7and20())
    }

    const background = (): string => {

        if (!initialSearchSuccessful) {
            return 'unchecked'
        }

        return isAllowed() ? 'allowed' : 'not-allowed'

    }

    function getQuery(latitude: number, longitude: number, place: string): string {

        switch (place) {
            case 'school': {
                return buildAllTagsQuery(latitude, longitude, place);
            }
            case 'kindergarten': {
                return buildAllTagsQuery(latitude, longitude, place);
            }
            case 'community_centre': {
                return buildAllTagsQuery(latitude, longitude, place);
            }
            case 'childcare': {
                return buildAllTagsQuery(latitude, longitude, place);
            }
            case 'playground': {
                return buildAllTagsQuery(latitude, longitude, place);
            }
            case 'sports_centre': {
                return buildSportsQuery(latitude, longitude)
            }
            case 'pedestrian': {
                return `
                [out:json];
                (
                  way["highway"="pedestrian"](around:${radius},${latitude},${longitude});
                  relation["highway"="pedestrian"](around:${radius},${latitude},${longitude});
                );
                out geom;`;
            }
            default: {
                console.error('place not found: ', place)
                return ''
            }
        }

    }

    const buildAllTagsQuery = (latitude: number, longitude: number, place: string): string => {
        const tags = ["leisure", "amenity", "building"];

        const queries = tags.map(tag =>
            `node["${tag}"="${place}"](around:${radius},${latitude},${longitude});
         way["${tag}"="${place}"](around:${radius},${latitude},${longitude});
         relation["${tag}"="${place}"](around:${radius},${latitude},${longitude});`
        ).join("");

        return `
        [out:json];
        (
            ${queries}
        );
        out geom;`;
    };

    const buildSportsQuery = (latitude: number, longitude: number): string => {
        const leisureTypes = [
            "sports_centre", "stadium", "sports_hall", "fitness_station",
            "ice_rink", "horse_riding", "track",
            "water_park", "golf_course", "indoor_play"
        ];

        const queries = leisureTypes.map(leisureType =>
            `node["leisure"="${leisureType}"](around:${radius},${latitude},${longitude});
         way["leisure"="${leisureType}"](around:${radius},${latitude},${longitude});
         relation["leisure"="${leisureType}"](around:${radius},${latitude},${longitude});`
        ).join("");

        return `
        [out:json];
        (
            ${queries}
        );
        out geom;`;
    };

    if (loading) {
        return <EmojiSpinner/>;
    }

    return (
        <div style={{minHeight: `${viewportHeight}px`}} className={'mobile-view-container ' + background()}>

            <div>
                <div className={"center"}>
                    {!initialSearchSuccessful && <div>
                        <p className={'intro-text'}>Du willst einfach nur 🥦 rauchen - hast aber keine Ahnung von
                            den <a href={'https://www.gesetze-im-internet.de/kcang/__5.html'}>Abstandsregeln</a> 📋?
                        </p> <p className={'intro-text'}>Gib mal deinen Standort und ich versuche dir zu
                        helfen</p>

                        <button className={'get-location-button'} onClick={getLocation}>
                            Standort freigeben 📍
                        </button>

                    </div>}

                    {initialSearchSuccessful && <>
                        <div className={"header"}>
                            <b className={"search-radius"}>Radius</b>
                            <select value={radius} onChange={e => setRadius(Number(e.target.value))}>
                                <option value={10}>10 m (ggf. Sichtweite)</option>
                                <option value={50}>50 m (ggf. Sichtweite)</option>
                                <option value={100}>100 m (ggf. Sichtweite)</option>
                                <option value={150}>150 m</option>
                                <option value={200}>200 m</option>
                                <option value={500}>500 m</option>
                            </select>
                        </div>
                        <hr/>
                    </>}

                    {initialSearchSuccessful && <div>
                        {!isAllowed() &&
                            <div>
                                <div className={'text'}>Achtung! Hier sind ein paar Orte in der Nähe, die
                                    problematisch sein könnten
                                </div>
                                <div className={"result-container"}>
                                    <div>
                                        {schools.map((p, index) => (
                                            <p key={index}>{p.tags.name || 'unbekannte Schule'} {p.tags["addr:street"]} {p.tags["addr:housenumber"]} [Schule]
                                            </p>
                                        ))}
                                    </div>
                                    <div>
                                        {playground.map((p, index) => (
                                            <p key={index}>{p.tags.name || 'unbekannter Kinderspielplatz'} {p.tags["addr:street"]} {p.tags["addr:housenumber"]} [Kinderspielplatz]</p>
                                        ))}
                                    </div>
                                    <div>
                                        {youthCentre.map((p, index) => (
                                            <p key={index}> {p.tags.name || ' unbekannte Kinder-/Jugendeinrichtung'} {p.tags["addr:street"]} {p.tags["addr:housenumber"]} [Kinder-/Jugendeinrichtung] </p>
                                        ))}
                                        {kindergarten.map((p, index) => (
                                            <p key={index}>{p.tags.name || 'unbekannte Kinder-/Jugendeinrichtung'} {p.tags["addr:street"]} {p.tags["addr:housenumber"]} [Kinder-/Jugendeinrichtung]</p>
                                        ))}
                                        {childcare.map((p, index) => (
                                            <p key={index}>{p.tags.name || 'unbekannte Kinder-/Jugendeinrichtung'} {p.tags["addr:street"]} {p.tags["addr:housenumber"]} [Kinder-/Jugendeinrichtung]</p>
                                        ))}
                                    </div>
                                    <div>
                                        {sportsCentre.map((p, index) => (
                                            <p key={index}>{p.tags.name || 'unbekannte Sportstätte'} {p.tags["addr:street"]} {p.tags["addr:housenumber"]} [Sportstätte]</p>
                                        ))}
                                    </div>
                                    <div>
                                        {pedestrianZone.filter(pz => isWithinBounds(location!, pz.bounds)).map((p, index) => (
                                            <p key={index}>{p.tags.name || 'unbekannte Fußgängerzone'} {p.tags["addr:street"]} {p.tags["addr:housenumber"]} [Fußgängerzone]</p>
                                        ))}
                                    </div>
                                </div>
                                <p className={'copyright'}>(Geodaten © <a
                                    href={'https://www.openstreetmap.org/'}>OpenStreetMap</a> und Mitwirkende, <a
                                    href={'https://opendatacommons.org/licenses/odbl/'}>ODbL</a>)
                                </p>
                            </div>}
                        {isAllowed() &&
                            <div className={'text'}>
                                <p>Sieht ganz entspannt aus hier...</p>

                                <p>Sei bitte trotzdem vorsichtig und prüfe deine Umgebung eigenständig</p>
                            </div>}
                    </div>}

                    <div className={'disclaimer'}>

                        {!initialSearchSuccessful &&
                            <p>
                                <b>Keine Gewähr auf Richtigkeit und Vollständigkeit der Ergebnisse. Achte bitte immer selbst auf die korrekte Einhaltung der Regeln </b><br/><br/>
                                Der Standort wird einzig verwendet, um direkt von <a
                                    href={'https://www.openstreetmap.org/'}>OpenStreetMap</a> relevante
                                Orte in deiner Umgebung abzufragen und erreicht zu keinem Zeitpunkt meinen Server<br/>
                            </p>}

                        <Imprint isOpen={isImprintOpen} onClose={() => setImprintOpen(false)}/>
                        <div className={"footer-links"}><a onClick={() => setImprintOpen(true)}>Impressum</a></div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;

const isWithinBounds = (location: Location, bounds: Bounds | undefined): boolean => {
    if (!bounds) {
        return true
    }

    return location.lat >= bounds.minlat &&
        location.lat <= bounds.maxlat &&
        location.lon >= bounds.minlon &&
        location.lon <= bounds.maxlon;
}

const isBetween7and20 = (): boolean => {
    const now = new Date();
    return now.getHours() >= 7 && now.getHours() < 20;
}