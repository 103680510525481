import React from 'react';
import './Spinner.css';

const EmojiSpinner = () => {
    return (
        <div className="emoji-spinner">
            🥦
        </div>
    );
}

export default EmojiSpinner;